import React from "react";
import { DataMeta } from "../Models/DataMeta";
import { LocalData } from "../Models/LocalData";
import { Section } from "../Models/Section";
import { ResultType } from "../Models/Types/ResultType";
import { DependecyFinder } from "../Utils/DependencyFinder";
import { JsonParser } from "../Utils/JsonParser";
import { LocalStoragePatcher } from "../Utils/LocalStoragePatcher";
import Header from "./Header";
import { Intro } from "./Intro";
import SectionDescription from "./SectionDescription";
import Step from "./Step";

declare global {
    interface Window {
        _itlk:any;
        _itld:any;
    }
}

export interface Props {
  jsonPath?: string;
}

export interface State {
  metaData: DataMeta;
  section: Array<Section>;
  dependencyFiltered: Array<Section>;
  activeStep: number;
  isFinished: boolean;
  isStarted: boolean;
}



class FormBuilder extends React.Component<Props, State> {
    private formRef: React.RefObject<HTMLFormElement>;
    private depHandler = new DependecyFinder();
    private customerKey = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];

    private environment = window.location.hostname.indexOf("localhost") !== -1 ? "dev" : "prod";

    constructor(props: Props) {
        super(props);
        this.state = {
            activeStep: 0,
            section: [],
            metaData: new DataMeta({}),
            dependencyFiltered: [],
            isFinished: false,
            isStarted: false
        }
        this.formRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
        this.handleJumpToStep = this.handleJumpToStep.bind(this);
        this.handleStart = this.handleStart.bind(this);
    }
    componentDidMount() {
        const script = document.createElement("script");
        window._itlk="1137d1842104d2b56a69bb-3";
        window._itld="";
        script.async = true;
        script.src = "https://io.innertrends.com/itl.js";

        
        document.head.appendChild(script);
        
        this.loadPreExistingData();
        
    }

    loadPreExistingData(){
        const parser = new JsonParser();
        parser.getData(this.props.jsonPath).then(res => {
            const meta = parser.parseMetaData(res);

            if (meta) {
                this.setState({ metaData: meta });
            }

            if (this.environment === "dev")
            {
              this.getdata();
            }
            else {
              const requestOptions = {
                method: 'GET'
              };
              
              fetch('/results?key='+this.customerKey+"&survey="+this.state.metaData.surveyName, requestOptions)
                  .then(response => response.json())
                  .then(data => {
                      if (typeof data == "object") window.localStorage.setItem(this.customerKey, JSON.stringify(data));
                      this.getdata();
                  });
              }
            
            });
    }

    getdata() {
        const parser = new JsonParser();
        const localStoragePatcher = new LocalStoragePatcher();
        

        let existingData: any = window.localStorage.getItem(this.customerKey);

        parser.getData(this.props.jsonPath).then(res => {
            const meta = parser.parseMetaData(res);
            const data = parser.parseDataToModel(res);
            let dep;

            if (meta) {
                this.setState({ metaData: meta });
            }
        

            switch (existingData) {
                case null:
                    dep = this.depHandler.hadleDependency(data);
                    console.log(data);
                    this.setState({ section: data, dependencyFiltered: dep })
                    break;
                default:
                    existingData = JSON.parse(existingData) as LocalData;
                    const patched = localStoragePatcher.patchFormStateFromLocalStorage(data, existingData.results)
                    dep = this.depHandler.hadleDependency(patched);
                    this.setState({ section: patched, activeStep: existingData.activeStep, dependencyFiltered: dep, isStarted: existingData.isStarted });
                    break;
            }
        });
    }

    saveData() {
        const storageItem = new LocalData();
        let saveData = [];
        

        if (this.customerKey.length > 10)
        {

            let existingData = JSON.parse(window.localStorage.getItem(this.customerKey)) as LocalData;
            const newData = this.state.section[this.state.activeStep].questions.map(q => {
                const ret: ResultType = { name: q.details.name, value: q.value }
                return ret;
            });
            if (existingData) {
                saveData = [...newData, ...existingData.results].filter(
                    (set =>
                        (o: any) => set.has(o.name) ? false : set.add(o.name)
                    )(new Set(Array<ResultType>())));
            }
            else {
                saveData = newData;
            }
            storageItem.activeStep = this.state.activeStep === this.state.section.length - 1 ? this.state.activeStep : this.state.activeStep + 1;
            storageItem.surveyName = this.state.metaData.surveyName;
            storageItem.results = saveData;
            storageItem.isStarted = true;
            window.localStorage.setItem(this.customerKey, JSON.stringify(storageItem));
            
            if (this.environment !== "dev")
            {
              const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(storageItem)
              };
              fetch('/results?key='+this.customerKey+"&survey="+this.state.metaData.surveyName, requestOptions)
                  .then(response => response.json());
            }

        }
    }


    

  handleChange(e: any): void {
    let sectionItems = [...this.state.section];
    const section = sectionItems[this.state.activeStep];
    const question = section.questions.findIndex((x) => x.details.name === e.target.name);
    sectionItems[this.state.activeStep].questions[question].value = e.target.value;
    const depFilt = this.depHandler.hadleDependency(sectionItems);
    this.saveData();
    this.setState({ section: sectionItems, dependencyFiltered: depFilt });
  }

  handleNext(e: React.MouseEvent<HTMLButtonElement>): boolean {
    if (this.formRef.current.checkValidity() === true) {
      this.saveData();
      let actvStep = this.state.activeStep;
      if (actvStep < this.state.section.length - 1) {
        this.setState({ activeStep: actvStep + 1 });
      }
      this.formRef.current.classList.remove("was-validated");
      return true;
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.formRef.current.classList.add("was-validated");
    }
    return false;
  }
  handlePrev(e: React.MouseEvent<HTMLButtonElement>): void {
    let actvStep = this.state.activeStep;
    if (actvStep > 0) {
      this.setState({ activeStep: actvStep - 1 });
    }
  }
    handleFinish(e: React.MouseEvent<HTMLButtonElement>): void {
        if (this.handleNext(e)) {
            this.setState({ isFinished: true });
            
            if (this.environment === "dev")
            {
              this.state.metaData.surveyRedirect && window.location.replace("https://"+window.location.hostname+"/customer-journey-metrics/"+this.customerKey)
            }
            else
            {
              const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: window.localStorage.getItem(this.customerKey)
              };
              fetch('/results?key='+this.customerKey+"&survey="+this.state.metaData.surveyName, requestOptions)
                  .then(response => { 
                      this.state.metaData.surveyRedirect && window.location.replace("https://"+window.location.hostname+"/customer-journey-metrics/"+this.customerKey)
                  });
              }
            
            
        }
    }
  
  handleJumpToStep(e: React.MouseEvent<HTMLLIElement>) {
    const dataset = (e.target as HTMLLIElement).dataset;
    const clickedStep = parseInt(dataset.stepnr);
    if (clickedStep < this.state.activeStep) {
      this.setState({ activeStep: clickedStep });
    }
  }
    handleStart(e: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ isStarted: true });
        const localData = new LocalData();
        localData.results = [];
        localData.activeStep = 0;
        localData.isStarted = true;
        window.localStorage.setItem(this.customerKey, JSON.stringify(localData));
        
        if (this.environment !== "dev")
        {
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(localData)
          };
          fetch('/results?key='+this.customerKey+"&survey="+this.state.metaData.surveyName, requestOptions)
              .then(response => response.json());
        }
    }

    render() {
        if (!this.state.isStarted) {
            return (<Intro meta={this.state.metaData} handleStart={this.handleStart}></Intro>)
        }
        if (this.state.section.length !== 0) {
            
            return (
                <div>
                    <Header activeStep={this.state.activeStep} numberOfSteps={this.state.section.length} section={this.state.section} handleJumpToStep={this.handleJumpToStep} handlePrev={this.handlePrev} handleNext={this.handleNext} handleFinish={this.handleFinish} headerTitle={this.state.metaData.headerTitle} headerSubTitle={this.state.metaData.headerSubTitle}></Header>
                    <div className="p-6 content_wrap">
                    {this.state.section.length !== 0 ?
                        (<SectionDescription section={this.state.section[this.state.activeStep]}></SectionDescription>) :
                        (<div>No data</div>)
                    }
                    <div className="questions_wrap pl-5 pr-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col">
                                        <form ref={this.formRef} onSubmit={e => e.preventDefault()} noValidate>

                                            {this.state ?
                                                (this.state.dependencyFiltered.map((s, i) =>
                                                    <Step
                                                        key={s.title}
                                                        section={s}
                                                        activeStep={this.state.activeStep}
                                                        currentStep={i}
                                                        handleChange={this.handleChange}>
                                                    </Step>)) :
                                                (<div>No data</div>)}
                                        </form>
                                    </div>
                                </div>
                               <div className="row">
                                    <div className="col text-center mb-5  col-md-12 col-lg-6 pl-5">
                                        <div className="btn-group " role="group">
                                            <button className="btn btn-secondary" type="button" onClick={this.handlePrev}>Previous</button>

                                            {this.state.activeStep === this.state.section.length - 1 ?
                                                <button className="btn btn-primary" type="button" onClick={this.handleFinish}>Finish</button> :
                                                <button className="btn btn-primary" type="button" onClick={this.handleNext}>Next</button>
                                            }

                                        </div>
                                    </div>
                                        </div>
                                <div className="row">
                                    {this.state.isFinished ?
                                        <div className="alert alert-success" role="alert">
                                            Thank you for completing the form.
                                                </div> :
                                        null
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                    <iframe src="https://my.innertrends.com/citj/innertrends/wizard_my_data.php"></iframe>

                </div>
                

                
                    
            )
        }
        else return (<div>No data</div>)
    }
}
export default FormBuilder;
