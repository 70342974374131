import { Question } from "./Question";

export class Section {
    title: string;
    desctiption: string;
    video: string;
    stepName: string;
    questions: Array<Question>

    constructor(data: any) {
        this.title = data.title;
        this.desctiption = data.description;
        this.video = data.video;
        this.stepName = data.stepName;
        this.questions = data.questions.map((q: any) => new Question(q));
    }
}