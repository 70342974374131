import React from "react";
import { DataMeta } from "../Models/DataMeta";
import "../Assets/css/cjm_intro_white.css";
import logo from "../Assets/img/logo_full_InnerTrends.png";


import {
    TopLogo,
    FooterLogo,
    ProductLed,
    Facebook,
    Twitter,
    Linkedin,
    InnerTrendsLogoColor,
    IntroCover
} from "../Assets/img/";

export interface Props {
    meta: DataMeta;
    handleStart: any;
}


export interface State {
    errors: any;
    embeded: boolean;
}



export class Intro extends React.Component<Props,State> {

    private emailInput: React.RefObject<HTMLInputElement>;

    constructor(props: Props)
    {
        super(props);
        this.emailInput = React.createRef();
        
        this.state = {
            errors: {},
            embeded: true
        }

        this.handleGo = this.handleGo.bind(this);
        this.handleVideo = this.handleVideo.bind(this);
    }

    handleValidation(email: any)
    {
        let error = {
            text : '',
            code : 0
        };
        let formIsValid = true;

        

        if(!email)
        {
            formIsValid = false;
            error.text = "Please enter an email address";
            error.code = 1;

        }else if( typeof email !== 'undefined' ){
            let lastAtPos = email.lastIndexOf("@");
            let lastDotPos = email.lastIndexOf(".");

            if (
                !(
                  lastAtPos < lastDotPos &&
                  lastAtPos > 0 &&
                  email.indexOf("@@") == -1 &&
                  lastDotPos > 2 &&
                  email.length - lastDotPos > 2
                )
              ) {
                formIsValid = false;
                error.text = "Email is not valid";
                error.code = 2;
              }
        }


        this.setState({errors: error});

        return formIsValid;
    }

    handleGo()
    {
        if( this.handleValidation( this.emailInput.current.value ) )
        {

            const hError = {
                text : '',
                code : 0
            };

            const data = {
                email: this.emailInput.current.value,
                url: document.location.href
            }

            const requestOptons = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };

            if (document.getElementById("partner") != null){
                var iframe = (document?.querySelector('#partner') as HTMLIFrameElement) 
                iframe.contentWindow.postMessage(JSON.stringify(data),'https://'+document.location.hostname);
            } 

            fetch('/new-cjm', requestOptons)
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    
                    if (typeof json.redirectLink != "undefined")
                    {
                        if (json.redirectLink != "" && json.redirectLink != document.location.href)
                        {
                            window.location = json.redirectLink;  
                        }
                        else
                        {
                            this.props.handleStart();
                        }
                        
                    }else {
                        hError.text = "Something went wrong!";
                        hError.code = 3;
                        this.setState({errors: hError});
                    }
                })
                .catch(error => {
                    hError.text = "Something went wrong!";
                    hError.code = 4;
                    this.setState({errors: hError});
                });

            
            
        }
        else {
            this.emailInput.current.focus();
        }
    }

    handleVideo()
    {
        this.setState({embeded: true});
    }


    
    render() {

        let embeded = <div className="d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div className="intro_cover d-flex align-items-center justify-content-center">
                {/* <button className="cjm_play_btn" onClick={this.handleVideo}></button> */}
            </div>
        </div>;

        if(this.state.embeded)
        {
            embeded = <iframe title="demo" width="100%" height="300" src={this.props.meta.surveyVideo}></iframe>
        }
        
        return (
            <div className="cjm_wrapper">
                <div className="container h-100">
                    <div className="h-100 d-flex flex-row justify-content-around justify-content-md-center align-items-center">
                        <div className="logo-single d-flex align-items-center justify-content-center">
                            <a href="https://www.innertrends.com" target="_blank"><img src={InnerTrendsLogoColor} className="img-fluid" alt="logo" /></a>
                        </div>
                    </div>
                </div>
                <div className="cjmContent">
                    <div className="container">
                        <div className="content-header">
                            <div className="row">
                                <div className="col-xl-7 col-lg-6 col-md-12">
                                    <h1>{this.props.meta.surveyTitle}</h1>
                                    <h2>{this.props.meta.surveySubTitle}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-7 col-lg-6">
                                <div className="content-left" dangerouslySetInnerHTML={{ __html: this.props.meta.surveyDescription }}></div>
                                <div className="content-left" >
                                    <form action="" onSubmit={e => e.preventDefault()}>
                                        <div className="emailAddress">
                                            <input 
                                                ref={this.emailInput}
                                                type="text" 
                                                name="email"
                                                className="cjm-input" 
                                                placeholder="Email Address"
                                                autoComplete="off"
                                                />
                                            <div className="errorLog">{this.state.errors.text}</div>
                                        </div>
                                        <button className="cjm_btn no_margin" onClick={this.handleGo}>Get started</button>
                                        <div className="policy">
                                        By submitting the form, you consent to InnerTrends processing your personal data in accordance with the <a href="https://www.innertrends.com/privacy" target="_blank">InnerTrends Privacy Policy</a> and to getting marketing emails. You can unsubscribe at any time.  
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                
                                {embeded}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="cjmFooter">
                    <div className="container">
                        <div className="footer-top d-flex flex-column flex-lg-row justify-content-between align-items-center">
                            <div className="ft-left my-3 m-lg-0">
                                <a href="https://www.innertrends.com" className="ft_logo" title="innertrends">
                                    <img src={FooterLogo} />
                                </a>
                            </div>
                            <div className="ft-right d-flex flex-lg-row flex-column align-items-center justify-content-between flex-grow-1">
                                <ul className="ft_menu my-3 m-lg-0">
                                    <li>
                                        <a href="https://www.innertrends.com/privacy">Privacy</a>
                                    </li>
                                    <li>
                                        <a href="https://www.innertrends.com/terms">Terms of use</a>
                                    </li>
                                    <li>
                                        <a href="https://www.innertrends.com/security">Security</a>
                                    </li>
                                </ul>
                                <ul className="social my-3 m-lg-0">
                                    <li>
                                        <a href="https://www.linkedin.com/company/innertrends/" target="_blank">
                                            <img src={Linkedin}  />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/innertrends" target="_blank">
                                            <img src={Twitter} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/innertrends" target="_blank">
                                            <img src={Facebook} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="ft-copy">
                            <p className="text-center text-lg-left">© InnerTrends {new Date().getFullYear()}</p>
                        </div>
                    </div>

                </div>
                
            </div>
            
        );
        /*return (
            <div className="container mgt-50 ">                
                <div className="row mb-5">
                    <div className="col-md-3 col-xl-3 logo_intro">
                        <img src={TopLogo}  className="img-fluid" alt="logo"></img>
                    </div> 
                </div>
               
               
                <div className="row">
                    <div className="col-md-6 col-xl-6">
                        <h1>{this.props.meta.surveyTitle}</h1>
                        <div dangerouslySetInnerHTML={{ __html: this.props.meta.surveyDescription }}></div>
                        <div><p>Please save this URL for future reference: https://{document.location.host+document.location.pathname}</p></div>
                    </div>
                    <div className="col text-center">
                        <iframe title="demo" width="100%" height="300" src={this.props.meta.surveyVideo}></iframe>
                    </div>
                </div>
                <div className="row mgt-50">
                {this.props.meta.customerKey.length > 10 ?
                        <button type="button" className="btn btn-primary btn-lg col-6 offset-3" onClick={this.props.handleStart}>Let's get started</button> :
                        <p className="text-danger">Your Business concepts definitions link is not valid. Please contact your InnerTrends representative to provide you the correct link.</p>
                    }                    
                </div>
            </div>
        );*/
    }
}
