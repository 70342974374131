export class DataMeta {
    surveyName?: string;
    surveyRedirect?: string;
    surveyTitle : string;
    surveySubTitle : string;
    surveyDescription: string;
    surveyVideo: string;
    customerKey: string;
    headerTitle: string;
    headerSubTitle: string;

    constructor(data: any) {
        this.surveyTitle = data.title;
        this.surveySubTitle = data.subtitle;
        this.customerKey = window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
        this.surveyDescription = data.description;
        this.surveyVideo = data.video;
        this.surveyName = data.surveyName || undefined;
        this.surveyRedirect = data.surveyRedirect || undefined;
        this.headerTitle = data.headerTitle || undefined;
        this.headerSubTitle = data.headerSubTitle || undefined;
    }
}