import React from "react";
import { Section } from "../Models/Section";

export interface Props {
    section: Section
}
class SectionDescription extends React.Component<Props> {
    render() {
        return (
            <div className="main_question p-5">
                <div className="row pl-4">
                    <div className="col-md-6 align-self-end">
                            <h1>{this.props.section.title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: this.props.section.desctiption }}></p>
                    </div>
                    <div className="col-md-6 col-xl-4">                        
                    {this.props.section.video !== "" ?
                                        <div className="videoWrapper"><iframe title="demo" src={this.props.section.video} width="100%" ></iframe></div> :
                                        <div></div>
                                    }
                        
                        
                    </div>
                </div>
            </div>);
    }
}

export default SectionDescription;