import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";
import { TemplateTagHandler } from "../Utils/TemplateTagHandler";

export class TextArea extends React.Component<ITextQuestion> {
    templateHadler = new TemplateTagHandler();
    render() {
        return (
            <div className="form-group">
                <fieldset>
                    <h5>{this.templateHadler.parseTemplate(this.props.question.question)}</h5>
                    <p>{this.props.question.description}</p>
                    <textarea className="form-control"
                        name={this.props.question.details.name}
                        onChange={this.props.handleChange}
                        required={this.props.question.details.mandatory ? true : false}>{this.props.question.value}
                    </textarea>
                    <div className="invalid-feedback">
                        {validationMessage[this.props.question.details.validate]}
                    </div>
                </fieldset>
            </div>
        );
    }

}