import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";

export class MultipleImage extends React.Component<ITextQuestion> {
    constructor(props: ITextQuestion) {
        super(props);
        this.handleEventInternally = this.handleEventInternally.bind(this);
    }
    handleEventInternally(e: React.MouseEvent<HTMLInputElement>) {
        const evt = {
            target: {
                name: this.props.question.details.name,
                value: e.currentTarget.value,
            }
        }
        this.props.handleChange(evt);
    }
    render() {
        const style = { borderWidth: '3px', borderStyle: 'solid', borderColor: '#d41d8d', backgroundColor: '#fff', filter: 'grayscale(0%)' };

        return (
            <div className="form-group">
                <fieldset>
                    <h5>{this.props.question.question}</h5>
                    <p>{this.props.question.description}</p>
                    <div className="form-check">
                        {this.props.question.details.options.map((i, j) => {
                            return (
                                <div className="" key={j}>
                                    <label className={this.props.question.details.class ? this.props.question.details.class : "image-select"} htmlFor={i.name} style={this.props.question.value === i.name ? style : {  }}>
                                        <img
                                            src={i.option}
                                            alt={i.name}
                                        />
                                    </label>
                                    <input
                                        type="radio"
                                        id={i.name}
                                        name={this.props.question.details.name}
                                        value={i.name}
                                        checked={this.props.question.value === i.name ? true : false}
                                        onClick={this.handleEventInternally}
                                        required={this.props.question.details.mandatory ? true : false}
                                        style={{ display: "none" }}
                                    >
                                    </input>
                                    {this.props.question.details.options.length - 1 === j ?
                                        <div className="invalid-feedback">
                                            {this.props.question.details.validate ?
                                                validationMessage[this.props.question.details.validate] :
                                                validationMessage.required}
                                        </div> :
                                        null
                                    }
                                </div>
                            )
                        })}
                    </div>
                </fieldset>
            </div>
        )
    }
}