import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";
import "../Assets/css/matrixComponent.css";
interface State {
  matrixes: Array<{ text: string; options: Array<string> }>;
}
export class MatrixComponent extends React.Component<ITextQuestion, State> {
  constructor(props: ITextQuestion) {
    super(props);
    this.state = {
      matrixes: [{ text: "", options: [] }],
    };
    this.handleEventInternally = this.handleEventInternally.bind(this);
    this.add = this.add.bind(this);
  }
  componentDidMount() {
    if (this.props.question.value) {
      this.setState({ matrixes: [...this.props.question.value] });
    }
  }
  handleEventInternally(e: React.ChangeEvent<HTMLInputElement>) {
    let prevStateMatrix = [...this.state.matrixes];
    //in case the event is intiated by the checkbox
    if (e.target.type === "checkbox") {
      if (prevStateMatrix[parseInt(e.target.id)].options.includes(e.target.value)) {
        const index = prevStateMatrix[parseInt(e.target.id)].options.indexOf(e.target.value);
        prevStateMatrix[parseInt(e.target.id)].options.splice(index, 1);
      } else {
        prevStateMatrix[parseInt(e.target.id)].options.push(e.target.value);
      }
    } else {
      //in case the event is initiated by the text field
      prevStateMatrix[parseInt(e.target.id)].text = e.target.value;
    }
    this.setState((prevState) => ({ matrixes: prevStateMatrix }));

    const evt = {
      target: {
        name: e.target.name,
        value: prevStateMatrix,
      },
    };
    this.props.handleChange(evt);
  }
  add() {
    this.setState({ matrixes: [...this.state.matrixes, { text: "", options: ["recurring"] }] });
  }
  render() {
    const element = [];
    for (let index = 0; index < this.state.matrixes.length; index++) {
      element.push(
        <div className="matrixFormGroup">
          <div className="mf_left">
            <input
              className="form-control"
              type={this.props.question.details.validate}
              key={index}
              id={index.toString()}
              name={this.props.question.details.name}
              value={this.state.matrixes[index].text}
              required={this.props.question.details.mandatory ? true : false}
              onChange={this.handleEventInternally}
            ></input>
          </div>
          {this.props.question.details.options.map((x) => {
            return (
              <div className="mf_right">
                <div className="form-check">
                  <label className="mcContainer">
                    {x.option}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={this.props.question.details.name}
                      value={x.name}
                      id={index.toString()}
                      checked={this.state.matrixes[index].options.findIndex(y => y === x.name) === 0 ? false : true}
                      onChange={this.handleEventInternally}
                    ></input>
                    <span className="mcCheckmark"></span>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return (
      <div className="form-group">
        <fieldset>
          <h5>{this.props.question.question}</h5>
          <p>{this.props.question.description}</p>
          {element}
          <div className="invalid-feedback">{validationMessage[this.props.question.details.validate]}</div>
          <a href="#!" onClick={this.add}>[+] Add More</a>
        </fieldset>
      </div>
    );
  }
}
