import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import '../Assets/Toggle-Switches.css';
import '../Assets/Switch.css';
import { validationMessage } from "../Models/Types/ValidationMessageType";
import { TemplateTagHandler } from "../Utils/TemplateTagHandler";

export class YesNo extends React.Component<ITextQuestion> {
    templateHadler = new TemplateTagHandler();
    render() {
        return (
            <div className="form-group">
                <fieldset>
                    <h5>{this.templateHadler.parseTemplate(this.props.question.question)}</h5>
                    <p>{this.props.question.description}</p>
                    <div className="toggle form-check">
                        <input
                            type="radio"
                            value="true"
                            id={`boolean-yes-${this.props.question.details.name}`}
                            name={this.props.question.details.name}
                            onChange={this.props.handleChange}
                            checked={this.props.question.value === "true" ? true : false}
                            required={this.props.question.details.mandatory ? true : false}>
                        </input>
                        <label htmlFor={`boolean-yes-${this.props.question.details.name}`}>Yes</label>
                        <input
                            type="radio"
                            value="false"
                            id={`boolean-no-${this.props.question.details.name}`}
                            name={this.props.question.details.name}
                            checked={this.props.question.value === "false" ? true : false}
                            onChange={this.props.handleChange}>
                        </input>
                        <label htmlFor={`boolean-no-${this.props.question.details.name}`}>No</label>
                        <div className="invalid-feedback">
                            {this.props.question.details.validate ?
                                validationMessage[this.props.question.details.validate] :
                                validationMessage.required}
                        </div>
                    </div>

                </fieldset>
            </div>
        )
    }
}