import { Section } from "../Models/Section";

export class LocalStoragePatcher {
    patchFormStateFromLocalStorage(jsonData: Array<Section>, localData: Array<{ name: string, value: any }>): Array<Section> {
        jsonData.map(s => {
            return s.questions.map(q => {
                const match = localData.find(el => el.name === q.details.name)
                if (match) {
                    q.value = match.value;
                }
                return;
            });
        });
        return jsonData;
    }
}