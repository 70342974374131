import React from "react";
import "../Assets/Steps-Progressbar.css";
//import logo from "../Assets/img/logo_full_InnerTrends.png";
import { Section } from "../Models/Section";


export interface HeaderProps {
    activeStep: number;
    numberOfSteps: number;
    section: Array<Section>;
    handleJumpToStep: any;
    handlePrev: any;
    handleFinish: any;
    handleNext: any;
    headerTitle: any;
    headerSubTitle: any;
}

class Header extends React.Component<HeaderProps> {

    
    
    render() {
        const steps = [];
        for (let index = 0; index < this.props.numberOfSteps; index++) {
            steps.push(
                <li className={this.props.activeStep === index ? "active" : this.props.activeStep > index ? "previous" : ""}
                    key={index}
                    data-stepnr={index}
                    onClick={this.props.handleJumpToStep}    >
                    {this.props.section[index].stepName}
            </li>);
        }
        return (
            <div className="container header">
                <nav className="navbar navbar-light navbar-expand-md">
                    <div className="">
                        <div className="navbar-brand">                            
                            {/* <img src={logo}  className="img-fluid" alt="logo"></img> */}
                            <h1>{this.props.headerTitle}</h1>
                            {/* <p>for the company: <span><b>Swydo</b></span> </p> */}
                            <p className="about_txt"><em>{this.props.headerSubTitle}</em></p>
                        </div>
                        <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navcol-1">
                            <ul className="nav navbar-nav">
                                <li className="nav-item"></li>
                            </ul>
                        </div>
                        <div className="row questions_nav_wrap">
                            <h5 className="text-center">Questions:</h5>
                            <div className="steps-progressbar">
                                <ol>
                                    {steps}
                                </ol>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col text-center m-auto">
                                <div className="btn-group" role="group">
                                    <button className="btn btn-secondary" type="button" onClick={this.props.handlePrev}>Previous</button>

                                    {this.props.activeStep === this.props.section.length - 1 ?
                                        <button className="btn btn-success" type="button" onClick={this.props.handleFinish}>Finsh</button> :
                                        <button className="btn btn-primary" type="button" onClick={this.props.handleNext}>Next</button>
                                    }

                                </div>
                            </div>
                        </div> */}
                    </div>
                    
                </nav>
                {/* if user exist OR is new */}
                <div className="go_to_it" id="go_to_it">
                    <div className="logotip">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.27 122.63">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path className="cls-1" d="M0,0A20.2,20.2,0,0,1,4.32,3.19a100.08,100.08,0,0,0,7,7.23c14,12.64,27.88,25.48,41.84,38.19,3.54,3.12,7.06,6.28,10.3,9.71-4,4.35-8.27,8.41-12.48,12.55C47.31,66.46,44.14,61.65,40.74,57,29.64,41.78,18.63,26.48,7.55,11.24,4.89,7.58,2,4.06,0,0Z"/>
                                    <path className="cls-2" d="M4.32,3.19A51.84,51.84,0,0,1,8.84,5.94c19.64,14,39.25,28.14,59,42.1,4.06,2.6,6.6,6.78,9.34,10.61C82.93,66.3,88,74.5,94.25,81.8a38.77,38.77,0,0,0,4.18-34.41c-5.06-14.58-19.5-25.34-35-25.73-5.5-.35-10.91,1-16.11,2.61C42.1,20.77,37,17,32,13.22,41.4,6.78,53.1,4,64.43,4.48a55.93,55.93,0,0,1,53.4,57.89c-.19,12.54-5.31,24.6-13.14,34.27,6,8.82,12.54,17.22,18.58,26-9-5.66-17.31-12.37-26.1-18.35-3.51,2.1-6.83,4.54-10.48,6.39A56.09,56.09,0,0,1,9.26,79.54C3.73,64.31,4.84,46.23,13.67,32.45,16.93,37.38,20.6,42,23.94,46.91c1.52,2,.09,4.4-.11,6.54C20.42,69.22,28.73,86.3,42.7,94.09A38.53,38.53,0,0,0,82,93.52C71.89,85.65,61,78.83,51,70.87c4.21-4.14,8.48-8.2,12.48-12.55-3.24-3.43-6.76-6.59-10.3-9.71-14-12.71-27.81-25.55-41.84-38.19a100.08,100.08,0,0,1-7-7.23"/>
                                </g>
                            </g>
                        </svg>                        
                    </div>
                    <span>go back to <a href="https://my.innertrends.com">InnerTrends</a></span>
                </div>
                <div className="go_to_it" id="powered_by">
                    <span>developed by</span>
                    <div className="logotip">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.27 122.63">
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path className="cls-1" d="M0,0A20.2,20.2,0,0,1,4.32,3.19a100.08,100.08,0,0,0,7,7.23c14,12.64,27.88,25.48,41.84,38.19,3.54,3.12,7.06,6.28,10.3,9.71-4,4.35-8.27,8.41-12.48,12.55C47.31,66.46,44.14,61.65,40.74,57,29.64,41.78,18.63,26.48,7.55,11.24,4.89,7.58,2,4.06,0,0Z"/>
                                    <path className="cls-2" d="M4.32,3.19A51.84,51.84,0,0,1,8.84,5.94c19.64,14,39.25,28.14,59,42.1,4.06,2.6,6.6,6.78,9.34,10.61C82.93,66.3,88,74.5,94.25,81.8a38.77,38.77,0,0,0,4.18-34.41c-5.06-14.58-19.5-25.34-35-25.73-5.5-.35-10.91,1-16.11,2.61C42.1,20.77,37,17,32,13.22,41.4,6.78,53.1,4,64.43,4.48a55.93,55.93,0,0,1,53.4,57.89c-.19,12.54-5.31,24.6-13.14,34.27,6,8.82,12.54,17.22,18.58,26-9-5.66-17.31-12.37-26.1-18.35-3.51,2.1-6.83,4.54-10.48,6.39A56.09,56.09,0,0,1,9.26,79.54C3.73,64.31,4.84,46.23,13.67,32.45,16.93,37.38,20.6,42,23.94,46.91c1.52,2,.09,4.4-.11,6.54C20.42,69.22,28.73,86.3,42.7,94.09A38.53,38.53,0,0,0,82,93.52C71.89,85.65,61,78.83,51,70.87c4.21-4.14,8.48-8.2,12.48-12.55-3.24-3.43-6.76-6.59-10.3-9.71-14-12.71-27.81-25.55-41.84-38.19a100.08,100.08,0,0,1-7-7.23"/>
                                </g>
                            </g>
                        </svg>                        
                    </div>
                    <span> <a href="https://www.innertrends.com">InnerTrends</a></span>
                </div>
               
            </div>
        );
    }
}

export default Header;