import { LocalData } from "../Models/LocalData";

export class TemplateTagHandler {
  parseTemplate(input: string): string {
    let localData = new LocalData();
    const customerKey = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    localData = JSON.parse(window.localStorage.getItem(customerKey)) as LocalData;
    const identifier = input.substring(input.lastIndexOf("{") + 1, input.lastIndexOf("|"));
    const defaultText = input.substring(input.lastIndexOf("|") + 1, input.lastIndexOf("}") - 1);

    const foundLocalData = localData.results.find((x) => x.name === identifier);
    
    if (identifier !== undefined) {
      if (foundLocalData !== undefined) {
        return input.replace("{{" + identifier + "|" + defaultText + "}}", foundLocalData.value);
      } else return input.replace("{{" + identifier + "|" + defaultText + "}}", defaultText);
    } else return input;
  }
}
