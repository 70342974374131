import { DetailType } from "./Types/DetailType";
import { ValidationType } from "./Types/ValidationType";

export class Detail {
    type: DetailType;
    validate: ValidationType;
    name: string;
    class: string;
    mandatory: boolean;
    dependency: string;
    options: Array<any>;

    constructor(data:any) {
        this.name = data.name;
        this.validate = data.validate as ValidationType;
        this.mandatory = data.mandatory === "yes" ? true : false;
        this.type = data.type as DetailType;
        this.class = data.class;
        this.dependency = data.dependency;
        this.options = data.options;
    }
}