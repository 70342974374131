import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";
import { TemplateTagHandler } from "../Utils/TemplateTagHandler";

interface State {
    selectedOptions: Array<string>;
}
export class MultipleChoice extends React.Component<ITextQuestion, State> {
    templateHadler = new TemplateTagHandler();
    constructor(props: ITextQuestion) {
        super(props);
        this.state = {
            selectedOptions: []
        }
        this.handleEventInternally = this.handleEventInternally.bind(this);
    }
    handleEventInternally(e: React.ChangeEvent<HTMLInputElement>) {
        let prevStateText = [...this.state.selectedOptions];
        if (e.target.value === "true") {
            prevStateText[parseInt(e.target.dataset.id)] = e.target.name;
        } else {
            prevStateText[parseInt(e.target.dataset.id)] = undefined;
        }
        this.setState(prevState => ({ selectedOptions: prevStateText }))

        const evt = {
            target: {
                name: e.target.parentElement.id,
                value: prevStateText,
            }
        }
        this.props.handleChange(evt);
    }
    componentDidMount() {
        if (this.props.question.value) {
            this.setState({ selectedOptions: [...this.props.question.value] })
        }
    }
    render() {
        const styles = { fontSize: 16, padding: 5.6 }
        return (
            <div className="form-group">
                <fieldset>
                    <h5>{this.templateHadler.parseTemplate(this.props.question.question)}</h5>
                    <p>{this.props.question.description}</p>

                    {this.props.question.details.options.map((o, i) => {
                        return (
                            <div className="toggle form-check" id={this.props.question.details.name}>
                                <input
                                    type="radio"
                                    value="true"
                                    id={`check-yes-${o.name}`}
                                    data-id={i}
                                    name={o.name}
                                    onChange={this.handleEventInternally}
                                    checked={this.state.selectedOptions[i] ? true : false}
                                    required={this.props.question.details.mandatory ? true : false}>
                                </input>
                                <label htmlFor={`check-yes-${o.name}`}>Yes</label>
                                <input
                                    type="radio"
                                    value="false"
                                    id={`check-no-${o.name}`}
                                    data-id={i}
                                    name={o.name}
                                    checked={!this.state.selectedOptions[i] ? true : false}
                                    onChange={this.handleEventInternally}>
                                </input>
                                <label htmlFor={`check-no-${o.name}`}>No</label>
                                <span style={styles}>{o.option}</span>
                                <div className="invalid-feedback">
                                    {this.props.question.details.validate ?
                                        validationMessage[this.props.question.details.validate] :
                                        validationMessage.required}
                                </div>
                            </div>)
                    })}
                </fieldset>
            </div>
        )
    }
}
