import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";
import "../Assets/css/singleChoice.css";

export class SingleChoice extends React.Component<ITextQuestion> {
  render() {
    return (
      <div className="form-group">
        <fieldset>
        <h5>{this.props.question.question}</h5>
        <p>{this.props.question.description}</p>
        {this.props.question.details.options.map((x) => {
          return (
            <div className="form-check">
              <label className="form-check-label">
                {x.option}
                <input
                className="form-check-input"
                type="radio"
                name={this.props.question.details.name}
                value={x.name}
                checked={this.props.question.value === x.name ? true : false}
                onChange={this.props.handleChange}
                required={this.props.question.details.mandatory ? true : false}
              ></input>
                <span className="checkmark"></span>
              </label>
              <div className="invalid-feedback">{validationMessage[this.props.question.details.validate]}</div>
            </div>
          );
        })}
        </fieldset>
      </div>
    );
  }
}
