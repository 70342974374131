import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";
import { TemplateTagHandler } from "../Utils/TemplateTagHandler";

interface State {
    selectedOptions: Array<string>;
}
export class MultipleImages extends React.Component<ITextQuestion, State> {
    templateHadler = new TemplateTagHandler();
    constructor(props: ITextQuestion) {
        super(props);
        this.state = {
            selectedOptions: []
        }
        this.handleEventInternally = this.handleEventInternally.bind(this);
    }

    handleEventInternally(e: React.MouseEvent<HTMLInputElement>) {
        let prevStateText = [...this.state.selectedOptions];
        let key = prevStateText.indexOf(e.currentTarget.value);
        if( key !== -1 ) {
            prevStateText.splice(key, 1);
        } else {
            prevStateText.push(e.currentTarget.value)
        }
        this.setState(selectedOptions => ({ selectedOptions: prevStateText }))
        const evt = {
            target: {
                name: this.props.question.details.name,
                value: prevStateText,
            }
        }
        this.props.handleChange(evt);
    }
    componentDidMount() {
        if (this.props.question.value) {
            this.setState({ selectedOptions: [...this.props.question.value] })
        }
    }
    render() {
        const style = { borderWidth: '3px', borderStyle: 'solid', borderColor: '#d41d8d', backgroundColor: '#fff', filter: 'grayscale(0%)' };
        return (
            <div className="form-group">
                <fieldset>
                    <h5>{this.templateHadler.parseTemplate(this.props.question.question)}</h5>
                    <p>{this.props.question.description}</p>
                    <div className="form-check">
                        {this.props.question.details.options.map((i, j) => {
                            return (
                                <div className="" key={j}>
                                    <label className={this.props.question.details.class ? this.props.question.details.class : "image-select"} htmlFor={i.name} style={this.state.selectedOptions.some(item => i.name === item) ? style : {  }}>
                                        <img
                                            src={i.option}
                                            alt={i.name}
                                        />
                                    </label>
                                    <input
                                        type="radio"
                                        id={i.name}
                                        name={this.props.question.details.name}
                                        value={i.name}
                                        checked={this.state.selectedOptions.some(item => i.name === item) ? true : false}
                                        onClick={this.handleEventInternally}
                                        required={this.props.question.details.mandatory ? true : false}
                                        style={{ display: "none" }}
                                    >
                                    </input>
                                    {this.props.question.details.options.length - 1 === j ?
                                        <div className="invalid-feedback">
                                            {this.props.question.details.validate ?
                                                validationMessage[this.props.question.details.validate] :
                                                validationMessage.required}
                                        </div> :
                                        null
                                    }
                                </div>
                            )
                        })}
                    </div>
                </fieldset>
            </div>
        )
    }
}
