import React from 'react';
import ReactDOM from 'react-dom';
import FormBuilder from './Components/FormBuilder';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import FullStory from 'fullstory-react';

export function initReact(path){
  ReactDOM.render(
    <React.StrictMode>
      <FullStory orgId="o-1CV4J6-na1">
        <FormBuilder jsonPath={path}></FormBuilder>
      </FullStory>
    </React.StrictMode>,
    document.getElementById('survey-node')
  );
}

/* var surveyPath = "/survey_tr/"
var survey = "trackingBackendSurvey.json"
var environment = window.location.hostname.indexOf("localhost") !== -1 ? "dev" : "prod"; */


/* var surveyPath = "/survey_am/"
var survey = "actionableMetrics.json"
var environment = window.location.hostname.indexOf("localhost") !== -1 ? "dev" : "prod"; */

var surveyPath = "/survey_cjm/"
var survey = "actionableMetrics.json"
var environment = window.location.hostname.indexOf("localhost") !== -1 ? "dev" : "prod";

/* var surveyPath = "/survey_pl/"
var survey = "plgCustomerJourneyMetrics.json"
var environment = window.location.hostname.indexOf("localhost") !== -1 ? "dev" : "prod"; */

if (environment === "dev") surveyPath = "/";
//initReact('./trackingBackendSurvey.json');
//initReact('/survey_tr/trackingBackendSurvey.json');
//initReact('./actionableMetricsNarek.json');
//console.log(surveyPath+survey)
initReact(surveyPath+survey);


