import { Section } from "../Models/Section";

export class DependecyFinder {

    hadleDependency(section: Array<Section>) {
        return section.map(s => {
            const sec = new Section({ questions: [] });
            sec.title = s.title;
            sec.desctiption = s.desctiption;
            sec.video = s.video;

            s.questions.forEach(q => {
                if (q.details.dependency) {
                    section.forEach(x => x.questions.forEach(c => {
                        if ((c.details.name === q.details.dependency)) {
                            if (!(c.value === false || c.value === "" || c.value === undefined || c.value === "false"))
                                sec.questions.push(q)
                        }
                    }))
                }
                else sec.questions.push(q);
            });
            return sec;
        });
    }
}