import React from "react";
import { Question } from "../Models/Question";
import { Section } from "../Models/Section";
import { MatrixComponent } from "./MatrixComponent";
import { MultipleChoice } from "./MultipleChoice";
import { MultipleImages } from "./MultipleImages";
import { MultipleImage } from "./MultipleImage";
import { MultipleText } from "./MultipleText";
import { SingleChoice } from "./SingleChoice";
import { SingleText } from "./SingleText";
import { TimeZone } from "./TimeZone";
import { TextArea } from "./TextArea";
import { YesNo } from "./YesNo";

export interface Props {
  activeStep: number;
  currentStep: number;
  section: Section;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class Step extends React.Component<Props, {}> {
  createUI(question: Question) {
    switch (question.details.type) {
      case "boolean":
        return <YesNo question={question} key={question.details.name} handleChange={this.props.handleChange} />;
      case "single-text":
        return <SingleText question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "timezone":
          return <TimeZone question={question} key={question.question} handleChange={this.props.handleChange} />;  
      case "single-choice":
        return <SingleChoice question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "text-area":
        return <TextArea question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "multiple-text":
        return <MultipleText question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "matrix":
        return <MatrixComponent question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "multiple-choice":
        return <MultipleChoice question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "multiple-images":
        return <MultipleImages question={question} key={question.question} handleChange={this.props.handleChange} />;
      case "multiple-image":
        return <MultipleImage question={question} key={question.question} handleChange={this.props.handleChange} />;
      default:
        <div>Component Not Found </div>;
    }
  }
  render() {
    if (this.props.activeStep !== this.props.currentStep) return null;
    return (
      <div className=" col-md-12 col-lg-6 pl-5">
        {" "}
        {/* col-md-12 col-lg-6 p-2 */}
        <div className="col-md-12">
          {this.props.section.questions.map((q) => {
            return this.createUI(q);
          })}
        </div>
      </div>
    );
  }
}

export default Step;
