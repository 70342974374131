import { Detail } from "./Detail";

export class Question {
    question: string;
    description: string;
    details: Detail;
    value: any;

    constructor(data: any) {
        this.question = data.question;
        this.description = data.description;
        this.details = new Detail(data.details);
    }
}