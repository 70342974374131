import React from "react";
import { ITextQuestion } from "../Models/Interfaces/ITextQuestion";
import { validationMessage } from "../Models/Types/ValidationMessageType";
import { TemplateTagHandler } from "../Utils/TemplateTagHandler";
interface State {
    textFields: Array<string>;
}
export class MultipleText extends React.Component<ITextQuestion, State> {
    templateHadler = new TemplateTagHandler();
    constructor(props: ITextQuestion) {
        super(props);
        this.state = {
            textFields: [""]
        }
        this.handleEventInternally = this.handleEventInternally.bind(this);
        this.add = this.add.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }
    componentDidMount(){
        if(this.props.question.value){
            this.setState({textFields:[...this.props.question.value]})
        }
    }
    handleEventInternally(e: React.ChangeEvent<HTMLInputElement>) {

        let prevStateText = [...this.state.textFields];
        prevStateText[parseInt(e.target.id)] = e.target.value;
        this.setState(prevState => ({ textFields: prevStateText }))

        const evt = {
            target: {
                name: e.target.name,
                value: prevStateText,
            }
        }
        this.props.handleChange(evt);
    }
    add() {
        this.setState({ textFields: [...this.state.textFields, ""] })
    }
    removeItem(i: number):void {
        //let prevStateText = [...this.state.textFields];
        //console.table(this.state.textFields);
          var array = [...this.state.textFields]; // make a separate copy of the array
          if (i !== -1) {
            array.splice(i, 1);
            this.setState({ textFields: array });
          }
          const evt = {
            target: {
                name: this.props.question.details.name,
                value: array,
            }
        }
        this.props.handleChange(evt);
        return;
    }

    render() {
        const element = [];
        for (let index = 0; index < this.state.textFields.length; index++) {
            element.push(
                <div className="multiple-text-wrapper">
                    <input
                        className="form-control"
                        type={this.props.question.details.validate}
                        key={index}
                        id={index.toString()}
                        name={this.props.question.details.name}
                        value={this.state.textFields[index]}
                        required={this.props.question.details.mandatory ? true : false}
                        onChange={this.handleEventInternally}
                        >
                    </input>
                    <a href="#!" className="remove" onClick={this.removeItem.bind(this, index)} data-remove="remove">[-]</a>
                </div>
            )

        }
        return (
            <div className="form-group">
                <fieldset>
                    <h5>{this.templateHadler.parseTemplate(this.props.question.question)}</h5>
                    <p dangerouslySetInnerHTML={{ __html: this.templateHadler.parseTemplate(this.props.question.description) }}>
                    </p>
                    {element}
                    <div className="invalid-feedback">
                        {validationMessage[this.props.question.details.validate]}
                    </div>
                    
                    <a href="#!" onClick={this.add}>[+] Add More</a>
                </fieldset>
            </div>
        )
    }
}
