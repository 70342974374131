import { DataMeta } from "../Models/DataMeta";
import { Section } from "../Models/Section";

export class JsonParser {
    parseDataToModel(data: any): Array<Section> {
        const parsed: Array<Section> = data.surveyData.sections.map((x: any) => new Section(x));
        return parsed;
    }
    parseMetaData(data: any): DataMeta {
        const metaData = new DataMeta(data);
        return metaData;
    }
    getData(location: string): Promise<any> {
        return fetch(location).then(data => { return data.json() });
    }
}